import React from 'react';
import { Link } from 'react-router-dom';
import PendingProductsSercice from '../../services/pendingProducts.service';
import PropTypes from 'prop-types';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Avatar, Paper, TableRow, TableHead, TableContainer, TableCell, TableBody, IconButton, Table } from '@mui/material/';

const PendingProductsListTable = ({ pendingProducts, confirmApproval }) => {
  async function approveProduct(accountId, productId) {
    const response = await PendingProductsSercice.approve(accountId, productId);
    if (response.data.success) {
      confirmApproval();
    }
  }

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="Pending products list">
        <TableHead>
          <TableRow>
            <TableCell>#</TableCell>
            <TableCell></TableCell>
            <TableCell>Product Name</TableCell>
            <TableCell>Creator</TableCell>
            <TableCell>Price</TableCell>
            <TableCell>Type</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {pendingProducts &&
            pendingProducts.map((product) => (
              <TableRow hover key={product.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component="th" scope="row">
                  {product.id}
                </TableCell>
                <TableCell>
                  {' '}
                  <Avatar alt={product.name} src={product.pics[0]?.url} />
                </TableCell>
                <TableCell>{product.name}</TableCell>
                <TableCell>
                  {
                    <Link to={`/accounts/${product.account.id}`} target="_blank">
                      {product.account.userName}
                    </Link>
                  }
                </TableCell>
                <TableCell>${(product.price[0].price / 100).toFixed(2)}</TableCell>
                <TableCell>{product.price[0].paymentType}</TableCell>
                <TableCell>
                  <IconButton
                    aria-label="Approve product"
                    onClick={() => {
                      approveProduct(product.account.id, product.id);
                    }}
                  >
                    <CheckCircleIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

PendingProductsListTable.propTypes = {
  pendingProducts: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      name: PropTypes.string.isRequired
    })
  ),
  confirmApproval: PropTypes.func.isRequired
};
export default PendingProductsListTable;
