import React, { useState, useEffect, useCallback } from 'react';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import StarIcon from '@mui/icons-material/Star';
import ProductService from '../../services/product.service';
import PropTypes from 'prop-types';
import UploadImages from '../subcomponents/UploadImages';
import Grid from '@mui/material/Grid';
import { Divider } from '@mui/material';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import AlertDialog from '../subcomponents/AlertDialog';

export default function ProductPics({ handleCloseModal, productId, accountId }) {
  const [saved, setSaved] = useState(false);
  const [pics, setPics] = useState([]);
  const [askToDelete, setAskToDelete] = useState(false);

  const getProductPics = useCallback(
    (productId) => {
      ProductService.getPics(accountId, productId)
        .then((response) => {
          setPics(response.data.pics);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    [accountId]
  );

  useEffect(() => {
    if (productId && productId !== 'new' && productId !== '') {
      getProductPics(productId);
    }
  }, [productId, getProductPics]);

  const onDefaultClick = (picId) => {
    ProductService.defaultPic(accountId, productId, picId)
      .then(() => {
        getProductPics(productId);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleCancel = () => {
    setAskToDelete(false); // Hide the alert without performing deletion
  };

  const handleDelete = () => {
    ProductService.removePic(accountId, productId, askToDelete)
      .then(() => {
        getProductPics(productId);
        setAskToDelete(false);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  return (
    <Grid>
      {askToDelete && (
        <AlertDialog
          title={'Borrar pic'}
          message={'Desea borrar este pic?. Esta acción no tiene vuelta atras'}
          onConfirm={handleDelete}
          onCancel={handleCancel}
          btnConfirmText={'borrar'}
          btnCancelText={'cancelar'}
        />
      )}
      <Typography variant="h6" gutterBottom>
        Product pictures
      </Typography>
      <Grid container spacing={2} sx={{ pb: 2 }}>
        {pics &&
          pics.map((pic) => (
            <Grid item xs={4} key={pic.id}>
              <div style={{ position: 'relative' }} key={pic.id}>
                <img src={pic.imageURL} alt="Product alt here" style={{ width: '100%', height: 'auto' }} />
                <IconButton style={{ position: 'absolute', top: 0, left: 0 }} onClick={() => onDefaultClick(pic.id)}>
                  {pic.default ? <StarIcon /> : <StarBorderIcon />}
                </IconButton>
                <IconButton style={{ position: 'absolute', top: 0, right: 0 }} onClick={() => setAskToDelete(pic.id)}>
                  <DeleteIcon />
                </IconButton>
              </div>
            </Grid>
          ))}
      </Grid>
      <UploadImages id={[accountId, productId]} setSaved={setSaved} objUpload={ProductService.uploadPic} />
      <Typography variant="h8" gutterBottom sx={{ color: 'orange' }}>
        Max 2mb.
      </Typography>
      <Divider />
      <Grid container sx={{ p: 2 }}>
        <Button onClick={() => handleCloseModal(saved, saved)} variant="contained" sx={{ ml: 1 }}>
          Close
        </Button>
      </Grid>
    </Grid>
  );
}

ProductPics.propTypes = {
  handleCloseModal: PropTypes.func.isRequired,
  productId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  accountId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
};
