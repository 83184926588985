import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

function getStyles(name, selectedArray, theme) {
  return {
    fontWeight: selectedArray.indexOf(name) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium
  };
}

function MultipleSelectChip({ objs, label, handleSelectChange, selected }) {
  const theme = useTheme();
  const [selectedArray, setSelectedArray] = React.useState([]);

  useEffect(() => {
    if (selected.length > 0 && objs.length > 0) {
      const indexes = selected.map((id) => {
        const index = objs.findIndex((obj) => obj.id === id);
        return index !== -1 ? index : null;
      });
      setSelectedArray(indexes);
    }
  }, [selected, objs]);

  const handleChange = (event) => {
    const {
      target: { value }
    } = event;

    // On autofill we get a stringified value.
    const selectValue = typeof value === 'string' ? value.split(',') : value;
    setSelectedArray(selectValue);
    handleSelectChange(selectValue.map((index) => objs[index].id));
  };

  return (
    <div>
      <FormControl sx={{ width: '100%' }}>
        <InputLabel id="demo-multiple-chip-label">{label}</InputLabel>
        <Select
          labelId="demo-multiple-chip-label"
          id="demo-multiple-chip"
          multiple
          value={selectedArray}
          onChange={handleChange}
          input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
          renderValue={() => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selectedArray.map((value) => (
                <Chip key={value} label={objs.length > 0 ? objs[value].name : ''} />
              ))}
            </Box>
          )}
          MenuProps={MenuProps}
        >
          {objs.map((obj, index) => (
            <MenuItem key={obj.id} value={index} style={getStyles(obj.name, selectedArray, theme)}>
              {obj.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}

MultipleSelectChip.propTypes = {
  objs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      name: PropTypes.string.isRequired
    })
  ).isRequired,
  label: PropTypes.string.isRequired,
  handleSelectChange: PropTypes.func.isRequired,
  selected: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])).isRequired
};
export default MultipleSelectChip;
