import React from 'react';
import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer } from 'react';

// reducer - state management
import { LOGIN, LOGOUT, REGISTER } from './auth-reducer/actions';
import authReducer from './auth-reducer/auth';

// project import
import Loader from '../components/Loader';
import axios from '../helpers/axios';

// constant
const initialState = {
  isLoggedIn: false,
  isInitialized: false,
  user: null
};

// ==============================|| JWT CONTEXT & PROVIDER ||============================== //

const JWTContext = createContext(null);

export const JWTProvider = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, initialState);

  useEffect(() => {
    const checkAuthStatus = async () => {
      try {
        const response = await axios.get('/admin/status', { withCredentials: true });
        // If the user is authenticated, update the application state
        if (response.data.isLoggedIn) {
          dispatch({
            type: LOGIN,
            payload: {
              isLoggedIn: true,
              user: response.data.user
            }
          });
        } else {
          dispatch({
            type: LOGOUT
          });
        }
      } catch (error) {
        dispatch({
          type: LOGOUT
        });
      }
    };

    checkAuthStatus();
  }, []);

  const login = async (email, password) => {
    const response = await axios.post('/admin/signin', { email, password }, { withCredentials: true });
    dispatch({
      type: REGISTER,
      payload: {
        user: { email: email }
      }
    });
    return response.data;
  };

  const logout = async () => {
    await axios.post('/admin/logout', {}, { withCredentials: true });
    dispatch({
      type: LOGOUT
    });
  };

  const twoFAVerify = async (email, token) => {
    try {
      const response = await axios.post('/admin/twofaVerify', { email, token }, { withCredentials: true });
      const { user, success } = response.data;
      if (success) {
        dispatch({
          type: LOGIN,
          payload: {
            isLoggedIn: true,
            user
          }
        });
      }
      return response.data;
    } catch (error) {
      return { success: false };
    }
  };

  if (state.isInitialized !== undefined && !state.isInitialized) {
    return <Loader />;
  }

  return (
    <JWTContext.Provider
      value={{
        ...state,
        login,
        twoFAVerify,
        logout
      }}
    >
      {children}
    </JWTContext.Provider>
  );
};

JWTProvider.propTypes = {
  children: PropTypes.node
};

export default JWTContext;
