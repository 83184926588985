export function moneyFormatter(amount) {
  let formattedNumber = (Number(amount) / 100).toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });
  return formattedNumber;
}

export function getRequestParams(searchQuery, page, pageSize) {
  let params = {};

  if (searchQuery) {
    params['query'] = searchQuery;
  }

  if (page) {
    params['page'] = page - 1;
  }

  if (pageSize) {
    params['size'] = pageSize;
  }

  return params;
}

export function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}
