import React, { useEffect, useState } from 'react';
import { Grid, Card, CardContent, Typography } from '@mui/material';
import DashboardService from '../services/dashboard.service';

const initialData = {
  newCustomers: 0,
  newProducts: 0,
  salesCount: 0,
  salesAmount: 0
};

const Dashboard = () => {
  const [data, setData] = useState(initialData);
  useEffect(() => {
    DashboardService.get()
      .then((response) => {
        if (response.data.success) {
          setData(response.data.data);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6} md={4}>
        <Card>
          <CardContent>
            <Typography variant="h6" component="div">
              New Customers this Month
            </Typography>
            <Typography variant="h4">{data.newCustomers}</Typography>
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12} sm={6} md={4}>
        <Card>
          <CardContent>
            <Typography variant="h6" component="div">
              New Products this Month
            </Typography>
            <Typography variant="h4">{data.newProducts}</Typography>
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12} sm={6} md={4}>
        <Card>
          <CardContent>
            <Typography variant="h6" component="div">
              Quantity of Sales this Month
            </Typography>
            <Typography variant="h4">{data.salesCount}</Typography>
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12} sm={6} md={4}>
        <Card>
          <CardContent>
            <Typography variant="h6" component="div">
              Amount of Sales this Month
            </Typography>
            <Typography variant="h4">${(data.salesAmount / 100).toFixed(2)}</Typography>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default Dashboard;
