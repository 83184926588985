import React, { useState } from 'react';
import AccountService from '../../services/account.service';
import UploadImages from '../subcomponents/UploadImages';
import Grid from '@mui/material/Grid';
import { Divider } from '@mui/material';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import PropTypes from 'prop-types';

ProfilePic.propTypes = {
  handleCloseModal: PropTypes.func.isRequired,
  accountId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
};

export default function ProfilePic({ handleCloseModal, accountId }) {
  const [saved, setSaved] = useState(false);

  return (
    <Grid>
      <Typography variant="h6" gutterBottom>
        Account Picture
      </Typography>
      <UploadImages id={accountId} setSaved={setSaved} objUpload={AccountService.uploadProfilePic} />
      <Typography variant="h8" gutterBottom sx={{ color: 'orange' }}>
        Max 2mb.
      </Typography>
      <Divider />
      <Grid container sx={{ p: 2 }}>
        <Button onClick={() => handleCloseModal(saved, saved)} variant="contained" sx={{ ml: 1 }}>
          Close
        </Button>
      </Grid>
    </Grid>
  );
}
