import React, { useState, useEffect } from 'react';
import {
  Card,
  CardContent,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Grid,
  Box,
  Button,
  CardActions,
  CircularProgress,
} from '@mui/material';
import { Check, Close, Edit, Launch } from '@mui/icons-material';
import PlansService, { Plan, Feature } from '../services/plans.service';

// Feature names (you might want to move this to a separate file or fetch from API)
const featureNames: { [key: number]: string } = {
  1: 'Product Management',
  2: 'User Management',
  3: 'Reporting',
  4: 'API Access',
  5: 'Advanced Analytics',
};

const PlanList: React.FC = () => {
  const [plans, setPlans] = useState<Plan[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchPlans = async () => {
      try {
        const response = await PlansService.getAll();
        setPlans(response.data.plans);
        setLoading(false);
      } catch (err) {
        setError('Failed to fetch plans. Please try again later.');
        setLoading(false);
      }
    };

    fetchPlans();
  }, []);

  const handleEditFeatures = (planId: number) => {
    // Implement your edit features logic here
    console.log(`Editing features for plan ${planId}`);
  };

  const handleGoToStripe = (stripeProductId: string) => {
    const stripeDomain = process.env.REACT_APP_STRIPE_DOMAIN || 'https://dashboard.stripe.com';
    const url = `${stripeDomain}/products/${stripeProductId}`;
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ textAlign: 'center', color: 'error.main', mt: 4 }}>
        <Typography variant="h6">{error}</Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ flexGrow: 1, padding: 3 }}>
      <Typography variant="h4" gutterBottom>
        Available Plans
      </Typography>
      <Grid container spacing={3}>
        {plans.map((plan) => (
          <Grid item xs={12} sm={6} md={4} key={plan.id}>
            <Card>
              <CardContent>
                <Typography variant="h5" gutterBottom>
                  {plan.name}
                </Typography>
                <List>
                  {plan.defaultSpecs.feature.map((feature: Feature) => (
                    <ListItem key={feature.id}>
                      <ListItemIcon>
                        {feature.enabled ? (
                          <Check color="primary" />
                        ) : (
                          <Close color="error" />
                        )}
                      </ListItemIcon>
                      <ListItemText
                        primary={featureNames[feature.id]}
                        secondary={
                          feature.volumeLimits?.products
                            ? `Up to ${feature.volumeLimits.products} products`
                            : null
                        }
                      />
                    </ListItem>
                  ))}
                </List>
              </CardContent>
              <CardActions>
                <Button
                  startIcon={<Edit />}
                  onClick={() => handleEditFeatures(plan.id)}
                  variant="outlined"
                  color="primary"
                >
                  Edit Features
                </Button>
                {plan.stripeProductId && (
                  <Button
                    startIcon={<Launch />}
                    onClick={() => plan.stripeProductId && handleGoToStripe(plan.stripeProductId)}
                    variant="outlined"
                    color="secondary"
                  >
                    Stripe Product
                  </Button>
                )}
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default PlanList;