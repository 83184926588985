import axios from 'axios';

export const endpoints = {
  api: process.env.REACT_APP_API_URL,
  adminBase: 'admin/',
  pendingProducts: 'pendingProducts/',
  accounts: 'accounts/',
  products: '/products/',
  approve: '/approve',
  reject: '/reject'
};

const axiosConfig = {
  withCredentials: true
};

const getAll = (params) => {
  return axios.get(endpoints.api + endpoints.adminBase + endpoints.pendingProducts, {
    params: params,
    ...axiosConfig
  });
};

const approve = (accountId, productId, data) => {
  return axios.post(
    endpoints.api + endpoints.adminBase + endpoints.accounts + accountId + endpoints.products + productId + endpoints.approve,
    data,
    axiosConfig
  );
};

const reject = (accountId, productId) => {
  return axios.post(
    endpoints.api + endpoints.adminBase + endpoints.accounts + accountId + endpoints.products + productId + endpoints.reject,
    {},
    axiosConfig
  );
};

const AccountService = {
  getAll,
  approve,
  reject
};

export default AccountService;
