import React from 'react';

import BalanceAndExchange from './BalanceAndExchange';

const Utilities = () => {
  return (
    <div className="container">
      <header className="jumbotron">
        <BalanceAndExchange />
      </header>
    </div>
  );
};

export default Utilities;
