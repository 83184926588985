import React, { useState, useEffect } from 'react';
import AccountService from '../../services/account.service';
import { getRequestParams } from '../../helpers/utils';
import completeProfiles from './AccountUtils';
import AccountsListTable from './AccountsListTable';
import Modal from '../subcomponents/Modal';
import AccountEdit from './AccountEdit';

import {
  Pagination,
  Grid,
  Typography,
  Box,
  CircularProgress,
  Paper,
  InputBase,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  FormControl,
  Divider,
  Alert,
  Snackbar
} from '@mui/material';

import { Refresh as RefreshIcon, Search as SearchIcon, AddBox as AddBoxIcon } from '@mui/icons-material';

const Accounts = () => {
  const [accounts, setAccounts] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [openModal, setOpenModal] = useState(false);
  const [saved, setSaved] = useState(false);

  const pageSizes = [10, 20, 30];

  const handlePageSizeChange = (event) => {
    setPageSize(event.target.value);
    setPage(1);
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const onChangeSearchQuery = (e) => {
    const searchQuery = e.target.value;
    setSearchQuery(searchQuery);
  };

  const retrieveAccounts = () => {
    setAccounts(null);
    const params = getRequestParams(searchQuery, page, pageSize);

    AccountService.getAll(params).then(
      (response) => {
        let { accounts, totalPages } = response.data;
        accounts = completeProfiles(accounts);

        setAccounts(accounts);
        setCount(totalPages);
      },
      (error) => {
        const _content = (error.response && error.response.data) || error.message || error.toString();

        setAccounts(_content);
      }
    );
  };

  useEffect(retrieveAccounts, [page, pageSize, searchQuery]);

  function goToAccountForm() {
    setOpenModal(true);
  }

  const handleCloseModal = (event, wasSaved = false) => {
    if (wasSaved) {
      setSaved(true);
      retrieveAccounts();
    }
    setOpenModal(false);
    setTimeout(() => {
      setSaved(false);
    }, '4000');
  };

  return (
    <div className="container">
      <Snackbar open={saved} autoHideDuration={6000}>
        <Alert severity="success" variant="filled" sx={{ mb: 1 }}>
          The account was saved!
        </Alert>
      </Snackbar>
      <Modal open={openModal} title={'Edit account'}>
        <AccountEdit handleCloseModal={handleCloseModal} />
      </Modal>
      <Grid sx={{ mb: 1 }} container justifyContent="space-between">
        <Typography variant="h4" color="text.primary">
          {' '}
          Accounts{' '}
        </Typography>
        <Paper component="form" sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 500 }}>
          <IconButton sx={{ p: '10px' }} aria-label="refresh" onClick={retrieveAccounts}>
            <RefreshIcon />
          </IconButton>
          <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
          <InputBase
            sx={{ ml: 1, flex: 1 }}
            placeholder="Search by name or email"
            inputProps={{ 'aria-label': 'Search by name or email' }}
            value={searchQuery}
            onChange={onChangeSearchQuery}
          />
          <IconButton type="button" sx={{ p: '10px' }} aria-label="search" onClick={retrieveAccounts}>
            <SearchIcon />
          </IconButton>
          <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
          <IconButton
            type="button"
            sx={{ p: '10px' }}
            aria-label="search"
            onClick={() => {
              goToAccountForm();
            }}
          >
            <AddBoxIcon />
          </IconButton>
        </Paper>
      </Grid>
      {accounts?.length > 0 ? (
        <AccountsListTable accounts={accounts} />
      ) : accounts?.length === 0 ? (
        <Box sx={{ mt: 4, mb: 4 }}>No accounts found.</Box>
      ) : (
        <Box sx={{ textAlign: 'center' }}>
          <CircularProgress />
        </Box>
      )}
      <Grid sx={{ mt: 2 }} container>
        <FormControl size="small" sx={{ width: '6em' }}>
          <InputLabel id="demo-simple-select-label">Page size</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={pageSize}
            label="Page size"
            onChange={handlePageSizeChange}
          >
            {pageSizes.map((size) => (
              <MenuItem key={size} value={size}>
                {size}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Pagination
          count={count}
          page={page}
          siblingCount={1}
          boundaryCount={1}
          variant="outlined"
          shape="rounded"
          onChange={handlePageChange}
          size="large"
        />
      </Grid>
    </div>
  );
};

export default Accounts;
