import axios from 'axios';


export const endpoints = {
  api: process.env.REACT_APP_API_URL,
  adminBase: 'admin/',
  plans: 'plans/'
};

export interface Feature {
  id: number;
  enabled: boolean;
  volumeLimits?: {
    products?: number;
  };
}

export interface Feature {
  id: number;
  enabled: boolean;
}

export interface Plan {
  id: number;
  name: string;
  defaultSpecs: {
    feature: Feature[];
  };
  stripeProductId: string | null;
  createdAt: string;
  updatedAt: string;
}

export interface PlanResponse {
  totalItems: number;
  plans: Plan[];
  totalPages: number;
  currentPage: number;
}

const getAll = () => {
  return axios.get<PlanResponse>(`${endpoints.api}${endpoints.adminBase}${endpoints.plans}/`, {
    withCredentials: true
  });
};

const PlansService = {
  getAll
};

export default PlansService;