import React, { useState, useEffect } from 'react';
import PendingProductsService from '../../services/pendingProducts.service';
import { getRequestParams } from '../../helpers/utils';

import PendingProductsListTable from './PendingProductsListTable';
import {
  Paper,
  InputBase,
  IconButton,
  CircularProgress,
  Pagination,
  Divider,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Snackbar,
  Alert,
  Grid,
  Typography,
  Box
} from '@mui/material';

import { Refresh as RefreshIcon, Search as SearchIcon } from '@mui/icons-material/';

const PendingProducts = () => {
  const [pendingProducts, setPendingProducts] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [saved, setSaved] = useState('');

  const pageSizes = [10, 20, 30];

  const handlePageSizeChange = (event) => {
    setPageSize(event.target.value);
    setPage(1);
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const onChangeSearchQuery = (e) => {
    const searchQuery = e.target.value;
    setSearchQuery(searchQuery);
  };

  const retrievePendingProducts = () => {
    setPendingProducts(null);
    const params = getRequestParams(searchQuery, page, pageSize);

    PendingProductsService.getAll(params).then(
      (response) => {
        let { products, totalPages } = response.data;

        setPendingProducts(products);
        setCount(totalPages);
      },
      (error) => {
        const _content = (error.response && error.response.data) || error.message || error.toString();

        setPendingProducts(_content);
      }
    );
  };

  useEffect(retrievePendingProducts, [page, pageSize, searchQuery]);

  const confirmApproval = () => {
    retrievePendingProducts();
    setSaved('The product was approved!');
    setTimeout(() => {
      setSaved('');
    }, '4000');
  };

  return (
    <div className="container">
      <Snackbar open={saved !== ''} autoHideDuration={6000}>
        <Alert severity="success" variant="filled" sx={{ mb: 1 }}>
          {saved}
        </Alert>
      </Snackbar>
      <Grid sx={{ mb: 1 }} container justifyContent="space-between">
        <Typography variant="h4" color="text.primary">
          {' '}
          Pending approval products{' '}
        </Typography>
        <Paper component="form" sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 500 }}>
          <IconButton sx={{ p: '10px' }} aria-label="refresh" onClick={retrievePendingProducts}>
            <RefreshIcon />
          </IconButton>
          <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
          <InputBase
            sx={{ ml: 1, flex: 1 }}
            placeholder="Search by name or email"
            inputProps={{ 'aria-label': 'Search by name or email' }}
            value={searchQuery}
            onChange={onChangeSearchQuery}
          />
          <IconButton type="button" sx={{ p: '10px' }} aria-label="search" onClick={retrievePendingProducts}>
            <SearchIcon />
          </IconButton>
        </Paper>
      </Grid>
      {pendingProducts?.length > 0 ? (
        <PendingProductsListTable pendingProducts={pendingProducts} confirmApproval={confirmApproval} />
      ) : pendingProducts?.length === 0 ? (
        <Box sx={{ mt: 4, mb: 4 }}>No pending products found.</Box>
      ) : (
        <Box sx={{ textAlign: 'center' }}>
          <CircularProgress />
        </Box>
      )}
      <Grid sx={{ mt: 2 }} container>
        <FormControl size="small" sx={{ width: '6em' }}>
          <InputLabel id="demo-simple-select-label">Page size</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={pageSize}
            label="Page size"
            onChange={handlePageSizeChange}
          >
            {pageSizes.map((size) => (
              <MenuItem key={size} value={size}>
                {size}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Pagination
          count={count}
          page={page}
          siblingCount={1}
          boundaryCount={1}
          variant="outlined"
          shape="rounded"
          onChange={handlePageChange}
          size="large"
        />
      </Grid>
    </div>
  );
};

export default PendingProducts;
