import React, { useRef, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { a11yProps } from '../../helpers/utils';
import TabPanel from '../subcomponents/TabPanel';
import ProductPics from './ProductPics';

import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Alert from '@mui/material/Alert';
import ProductForm from './ProductForm';

const ProductEdit = ({ handleCloseModal, productId }) => {
  let params = useParams();
  let accountId = params.accountId;

  const [currentTab, setCurrentTab] = useState(0);
  const tabRef = useRef();
  const [tabHeight, setTabHeight] = useState(0);

  useEffect(() => {
    setTabHeight(tabRef.current.clientHeight);
  }, [tabRef]);

  const handleChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={currentTab} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="Data" {...a11yProps(0)} />
          <Tab label="Pics" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <TabPanel value={currentTab} index={0} innerRef={tabRef}>
        <ProductForm handleCloseModal={handleCloseModal} productId={productId} />
      </TabPanel>
      <TabPanel value={currentTab} index={1} sx={{ minHeight: tabHeight }}>
        {productId != '' ? (
          <ProductPics handleCloseModal={handleCloseModal} productId={productId} accountId={accountId} />
        ) : (
          <Alert severity="warning">Product most be saved before uploading the pics!</Alert>
        )}
      </TabPanel>
    </Box>
  );
};

ProductEdit.propTypes = {
  handleCloseModal: PropTypes.func.isRequired,
  productId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default ProductEdit;
