import React, { useState } from 'react';
import { moneyFormatter } from '../../helpers/utils';
import ProductService from '../../services/product.service';
import { useParams } from 'react-router-dom';

import PropTypes from 'prop-types';
import EditIcon from '@mui/icons-material/Edit';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Chip from '@mui/material/Chip';
import Avatar from '@mui/material/Avatar';
import AlertDialog from '../subcomponents/AlertDialog';

const ProductsListTable = ({ goToProductEdit, products, confirmDeletion }) => {
  let params = useParams();
  let accountId = params.accountId;
  const [askToDelete, setAskToDelete] = useState(false);

  function copyToClipboard(text) {
    navigator.clipboard
      .writeText(text)
      .then(() => console.log('Text copied to clipboard'))
      .catch((error) => console.error('Could not copy text: ', error));
  }

  const handleDelete = () => {
    ProductService.remove(accountId, askToDelete)
      .then(() => {
        confirmDeletion();
      })
      .catch((e) => {
        console.log(e);
      });
    setAskToDelete(false); // Hide the alert after deletion
  };

  const handleCancel = () => {
    setAskToDelete(false); // Hide the alert without performing deletion
  };

  return (
    <>
      {askToDelete && (
        <AlertDialog
          title={'Borrar producto'}
          message={'Desea borrar este producto?. Esta acción no tiene vuelta atras'}
          onConfirm={handleDelete}
          onCancel={handleCancel}
          btnConfirmText={'borrar'}
          btnCancelText={'cancelar'}
        />
      )}
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="Products list">
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell></TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Price</TableCell>
              <TableCell>Fee</TableCell>
              <TableCell align="center">Payment Type</TableCell>
              <TableCell>Payment Link</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {products &&
              products.map((product) => (
                <TableRow hover key={product.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component="th" scope="row">
                    {product.id}
                  </TableCell>
                  <TableCell>
                    <Avatar alt={product.name} src={product.pics[0]?.imageURL} />
                  </TableCell>
                  <TableCell>{product.name}</TableCell>
                  <TableCell>${moneyFormatter(product.price[0]?.price)}</TableCell>
                  {product.price[0]?.paymentType && product.price[0]?.paymentType === 'one_time' ? (
                    <>
                      <TableCell>${moneyFormatter(product.price[0]?.paymentLink?.fee)}</TableCell>
                      <TableCell align="center">
                        <Chip label="One Time" color="primary" />
                      </TableCell>
                    </>
                  ) : (
                    <>
                      <TableCell>{product.price[0]?.paymentLink?.feePercentage}%</TableCell>
                      <TableCell align="center">
                        <Chip label={'Recurring - ' + product.price[0]?.recurringInterval} color="success" />
                      </TableCell>
                    </>
                  )}
                  <TableCell>
                    <IconButton
                      aria-label="Copy"
                      onClick={() => {
                        copyToClipboard(product.price[0]?.paymentLink?.stripePaymentLinkURL);
                      }}
                    >
                      <ContentCopyIcon />
                    </IconButton>
                    <IconButton
                      aria-label="Go to payment link"
                      onClick={() => {
                        window.open(product.price[0]?.paymentLink?.stripePaymentLinkURL, '_blank');
                      }}
                    >
                      <ExitToAppIcon />
                    </IconButton>
                  </TableCell>
                  <TableCell>
                    <IconButton
                      aria-label="Edit product"
                      onClick={() => {
                        goToProductEdit(product.id);
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      aria-label="Delete product"
                      onClick={() => {
                        setAskToDelete(product.id);
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

ProductsListTable.propTypes = {
  goToProductEdit: PropTypes.func.isRequired,
  products: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      name: PropTypes.string.isRequired,
      price: PropTypes.arrayOf(
        PropTypes.shape({
          price: PropTypes.string.isRequired,
          paymentType: PropTypes.string,
          paymentLink: PropTypes.shape({
            fee: PropTypes.string,
            feePercentage: PropTypes.string,
            stripePaymentLinkURL: PropTypes.string
          }),
          recurringInterval: PropTypes.string
        })
      ).isRequired,
      pics: PropTypes.arrayOf(
        PropTypes.shape({
          imageURL: PropTypes.string
        })
      )
    })
  ).isRequired,
  confirmDeletion: PropTypes.func.isRequired
};

export default ProductsListTable;
