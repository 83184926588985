import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL + 'public/tags/';

const getAll = () => {
  return axios.get(API_URL);
};

const TagService = {
  getAll
};

export default TagService;
