import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function AlertDialog({ title, message, btnConfirmText, onConfirm, btnCancelText, onCancel }) {
  return (
    <div>
      <Dialog open={true} onClose={onCancel} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{message}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="success" onClick={onConfirm}>
            {btnConfirmText}
          </Button>
          <Button variant="outlined" color="primary" onClick={onCancel} autoFocus>
            {btnCancelText}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

AlertDialog.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  btnConfirmText: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
  btnCancelText: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired
};
