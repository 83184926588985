import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL + 'accounts/';
const ADMIN_API_URL = process.env.REACT_APP_API_URL + 'admin/accounts/';

const axiosConfig = {
  withCredentials: true
};

const getAll = (params) => {
  return axios.get(API_URL, {
    params: params,
    ...axiosConfig
  });
};

const get = (id) => {
  return axios.get(API_URL + id, axiosConfig);
};

const create = (data) => {
  return axios.post(API_URL, data, axiosConfig);
};

const update = (id, data) => {
  return axios.post(API_URL + id, data, axiosConfig);
};

const uploadProfilePic = (id, file, onUploadProgress) => {
  let config = {
    ...axiosConfig,
    onUploadProgress: onUploadProgress
  };
  return axios.post(API_URL + id + '/profilePic', file, config);
};

const loginIntoAccount = (id, email) => {
  return axios.post(ADMIN_API_URL + id + '/openAccount', { email: email }, axiosConfig);
};

const AccountService = {
  getAll,
  get,
  create,
  update,
  uploadProfilePic,
  loginIntoAccount
};

export default AccountService;
