import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { Divider, Input } from '@mui/material';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import AccountService from '../../services/account.service';
import 'react-quill/dist/quill.snow.css';
import PropTypes from 'prop-types';

AccountForm.propTypes = {
  handleCloseModal: PropTypes.func.isRequired
};

function AccountForm({ handleCloseModal }) {
  const params = useParams();
  const { accountId } = params;

  const initialAccountState = {
    id: null,
    email: '',
    userName: '',
    firstName: '',
    lastName: '',
    bio: '',
    receiveNewsByEmail: true,
    dayOfBirth: '',
    completedProfile: false,
    stripeAccountId: '',
    instagram: '',
    facebook: '',
    tiktok: '',
    linkedin: '',
    twitter: ''
  };

  const [account, setAccount] = useState(initialAccountState);
  const [isSaving, setIsSaving] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  const handleInputChange = (event) => {
    let { name, value } = event.target;
    if (name === 'receiveNewsByEmail') {
      value = event.target.checked;
    }

    setAccount({ ...account, [name]: value });
  };

  const [editing, setEditing] = useState(false);

  const getAccount = (id) => {
    AccountService.get(id)
      .then((response) => {
        if (response && response.data) {
          let parsedData = response.data;
          parsedData.receiveNewsByEmail = response.data.receiveNewsByEmail === '1';
          parsedData.instagram = response.data.socialMedia?.instagram ?? '';
          parsedData.facebook = response.data.socialMedia?.facebook ?? '';
          parsedData.tiktok = response.data.socialMedia?.tiktok ?? '';
          parsedData.linkedin = response.data.socialMedia?.linkedin ?? '';
          parsedData.twitter = response.data.socialMedia?.twitter ?? '';

          setAccount(parsedData);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    if (accountId && accountId !== 'new') {
      setEditing(true);
      getAccount(accountId);
    }
  }, [accountId]);

  const saveAccount = () => {
    setIsSaving(true);
    const data = {
      email: account.email,
      userName: account.userName,
      firstName: account.firstName,
      lastName: account.lastName,
      bio: account.bio,
      receiveNewsByEmail: account.receiveNewsByEmail,
      completedProfile: account.completedProfile,
      stripeAccountId: account.stripeAccountId,
      instagram: account.instagram,
      facebook: account.facebook,
      tiktok: account.tiktok,
      linkedin: account.linkedin,
      twitter: account.twitter
    };

    if (editing) {
      AccountService.update(accountId, data)
        .then(() => {
          handleCloseModal(true, true);
        })
        .catch((e) => {
          setAlertMessage(e.response.data.message);
          setTimeout(() => {
            setAlertMessage('');
          }, '6000');
          console.log(e);
          setIsSaving(false);
        });
    } else {
      // Fake password for the account creation. For the user to login in the future they will have to change this.
      data.password = process.env.REACT_APP_FAKE_PASS;
      AccountService.create(data)
        .then(() => {
          handleCloseModal(true, true);
        })
        .catch((e) => {
          setAlertMessage(e.response.data.message);
          setTimeout(() => {
            setAlertMessage('');
          }, '6000');
          console.log(e);
          setIsSaving(false);
        });
    }
  };

  return (
    <div>
      <Snackbar open={alertMessage !== ''}>
        <Alert severity="error">{alertMessage}</Alert>
      </Snackbar>
      <form>
        <Grid container sx={{ p: 2 }}>
          <Grid container>
            <Typography variant="h6" gutterBottom>
              Personal Information
            </Typography>
          </Grid>
          <FormControl sx={{ width: '49%', mr: '1%', mt: 2 }}>
            <InputLabel htmlFor="userName">User Name</InputLabel>
            <Input
              name="userName"
              id="userName"
              autoComplete="userName"
              aria-describedby="User Name"
              onChange={handleInputChange}
              value={account.userName}
            />
          </FormControl>
          <FormControl sx={{ width: '49%', mr: '1%', mt: 2 }}>
            <InputLabel htmlFor="email">Email address</InputLabel>
            <Input
              name="email"
              id="email"
              autoComplete="email"
              aria-describedby="Email address"
              onChange={handleInputChange}
              value={account.email}
            />
          </FormControl>
        </Grid>
        <Grid container>
          <Grid container>
            <FormControl sx={{ width: '49%', mr: '1%', mt: 2 }}>
              <InputLabel htmlFor="firstName">First Name</InputLabel>
              <Input name="firstName" id="firstName" aria-describedby="First name" onChange={handleInputChange} value={account.firstName} />
            </FormControl>
            <FormControl sx={{ width: '49%', ml: '1%', mt: 2 }}>
              <InputLabel htmlFor="lastName">Last Name</InputLabel>
              <Input name="lastName" id="lastName" aria-describedby="Last Name" onChange={handleInputChange} value={account.lastName} />
            </FormControl>
          </Grid>
          <Grid container>
            <FormControl sx={{ width: '100%', ml: '1%', mt: 2 }}>
              <InputLabel htmlFor="bio">Bio</InputLabel>
              <Input
                name="bio"
                id="bio"
                autoComplete="email"
                aria-describedby="Email address"
                onChange={handleInputChange}
                value={account.bio}
              />
            </FormControl>
          </Grid>
          <Grid container>
            <FormControl sx={{ width: '49%', mr: '1%', mt: 2 }}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="receiveNewsByEmail"
                    id="receiveNewsByEmail"
                    onChange={handleInputChange}
                    checked={account.receiveNewsByEmail}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                }
                label="Receive news by email"
              />
            </FormControl>
          </Grid>
          <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
            Social Media
          </Typography>
          <Grid container>
            <FormControl sx={{ width: '49%', mr: '1%', mt: 2 }}>
              <InputLabel htmlFor="instagram">Instagram</InputLabel>
              <Input name="instagram" id="instagram" aria-describedby="Instagram" onChange={handleInputChange} value={account.instagram} />
            </FormControl>
            <FormControl sx={{ width: '49%', ml: '1%', mt: 2 }}>
              <InputLabel htmlFor="facebook">Facebook</InputLabel>
              <Input name="facebook" id="facebook" aria-describedby="Facebook" onChange={handleInputChange} value={account.facebook} />
            </FormControl>
          </Grid>
          <Grid container>
            <FormControl sx={{ width: '49%', mr: '1%', mt: 2 }}>
              <InputLabel htmlFor="tiktok">Tiktok</InputLabel>
              <Input name="tiktok" id="tiktok" aria-describedby="Tiktok" onChange={handleInputChange} value={account.tiktok} />
            </FormControl>
            <FormControl sx={{ width: '49%', ml: '1%', mt: 2 }}>
              <InputLabel htmlFor="linkedin">Linkedin</InputLabel>
              <Input name="linkedin" id="linkedin" aria-describedby="Linkedin" onChange={handleInputChange} value={account.linkedin} />
            </FormControl>
          </Grid>
          <Grid container>
            <FormControl sx={{ width: '49%', mr: '1%', mt: 2 }}>
              <InputLabel htmlFor="twitter">Twitter</InputLabel>
              <Input name="twitter" id="twitter" aria-describedby="Twitter" onChange={handleInputChange} value={account.twitter} />
            </FormControl>
          </Grid>
        </Grid>
        <Divider sx={{ mb: 1 }} />
        {!isSaving ? (
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Button onClick={handleCloseModal} variant="contained" color="error">
              Cancel
            </Button>
            <Button onClick={saveAccount} variant="contained">
              Save
            </Button>
          </Box>
        ) : (
          <Box sx={{ textAlign: 'center' }}>
            <CircularProgress />
          </Box>
        )}
      </form>
    </div>
  );
}

export default AccountForm;
