import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL + 'balance/';

const axiosConfig = {
  withCredentials: true
};

const get = () => {
  return axios.get(API_URL, axiosConfig);
};

const pygToUsd = () => {
  return axios.post(API_URL, {}, axiosConfig);
};

const BalanceService = {
  pygToUsd,
  get
};

export default BalanceService;
