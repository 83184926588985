import React from 'react';
import AccountService from '../../services/account.service';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Tooltip, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Avatar } from '@mui/material';
import { AccountBox as AccountBoxIcon, CheckCircleOutline as CheckCircleOutlineIcon, Login as LoginIcon } from '@mui/icons-material';

const AccountsListTable = ({ accounts }) => {
  let navigate = useNavigate();

  function goToAccountProfile(id) {
    navigate('/accounts/' + id);
  }

  async function loginToAccount(id, email) {
    const response = await AccountService.loginIntoAccount(id, email);
    if (response.data.success) {
      window.open(process.env.REACT_APP_WEBAPP_URL + 'login?ut=' + response.data.token, '_blank');
    }
  }

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="Accounts list">
        <TableHead>
          <TableRow>
            <TableCell>#</TableCell>
            <TableCell></TableCell>
            <TableCell>Full Name</TableCell>
            <TableCell>Email</TableCell>
            <TableCell align="center">Missing data</TableCell>
            <TableCell>Stripe ID</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {accounts &&
            accounts.map((account) => (
              <TableRow hover key={account.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component="th" scope="row">
                  {account.id}
                </TableCell>
                <TableCell>
                  {' '}
                  <Avatar alt={account.firstName + ' ' + account.lastName} src={account.profilePicture} />
                </TableCell>
                <TableCell>{account.firstName + ' ' + account.lastName}</TableCell>
                <TableCell>{account.email}</TableCell>
                <TableCell align="center">
                  {account.completedProfile === true ? <CheckCircleOutlineIcon style={{ color: 'green' }} /> : account.completedProfile}
                </TableCell>
                <TableCell>
                  {account.stripeAccountId ? (
                    <a href={'https://dashboard.stripe.com/connect/accounts/' + account.stripeAccountId} target="_blank" rel="noreferrer">
                      {account.stripeAccountId}
                    </a>
                  ) : (
                    '-'
                  )}
                </TableCell>
                <TableCell>
                  <Tooltip title="Go to Account">
                    <IconButton
                      aria-label="Account profile"
                      onClick={() => {
                        goToAccountProfile(account.id);
                      }}
                    >
                      <AccountBoxIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Login into Account">
                    <IconButton
                      aria-label="Login into Account"
                      onClick={() => {
                        loginToAccount(account.id, account.email);
                      }}
                    >
                      <LoginIcon />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

AccountsListTable.propTypes = {
  accounts: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      profilePicture: PropTypes.string,
      completedProfile: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
      stripeAccountId: PropTypes.string
    })
  ).isRequired
};
export default AccountsListTable;
