import React, { useEffect, useState } from 'react';
import { moneyFormatter } from '../../helpers/utils';
import { useParams } from 'react-router-dom';

import TagService from '../../services/tag.service';
import ProductService from '../../services/product.service';
import PropTypes from 'prop-types';
import MultipleSelectChip from '../subcomponents/MultipleSelectChip';
import 'react-quill/dist/quill.snow.css';

import {
  FormControlLabel,
  Checkbox,
  CircularProgress,
  Box,
  Snackbar,
  Alert,
  MenuItem,
  Select,
  InputAdornment,
  Button,
  Grid,
  InputLabel,
  FormControl,
  Divider,
  Input
} from '@mui/material';

const paymentTypes = [
  {
    value: 'one_time',
    label: 'One Time'
  },
  {
    value: 'recurring',
    label: 'Subscription'
  }
];

const recurringIntervals = [
  {
    value: 'year',
    label: 'Year'
  },
  {
    value: 'month',
    label: 'Month'
  },
  {
    value: 'week',
    label: 'Week'
  },
  {
    value: 'day',
    label: 'Day'
  }
];

const afterCompletionTypes = [
  {
    value: 'hosted_confirmation',
    label: 'Hosted confirmation'
  },
  {
    value: 'redirect',
    label: 'Redirect'
  }
];

const ProductForm = ({ handleCloseModal, productId }) => {
  let params = useParams();
  let accountId = params.accountId;

  const initialProductState = {
    id: null,
    name: '',
    description: '',
    imageURL: '',
    price: 0,
    stripeProductId: '',
    stripePriceId: '',
    feePercentage: '10%',
    paymentType: 'one_time',
    recurringInterval: '',
    feeAmount: 0,
    afterCompletion: 'hosted_confirmation',
    redirectURL: '',
    accountId: null,
    shouldBePublished: true
  };

  const [product, setProduct] = useState(initialProductState);
  const [tags, setTags] = useState([]);
  const [editing, setEditing] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  const handleInputChange = (event) => {
    let { name, value } = event.target;
    if (name === 'price') {
      value = value.replace(/[^\d]/g, '');
      let feePercentage = 10;
      let feeAmount = parseInt(value) / 10;
      if (value < 2000) {
        feePercentage = ((2 * 100) / (value / 100).toFixed(2)).toFixed(2);
        feeAmount = 200;
      }
      setProduct({ ...product, price: moneyFormatter(value), feeAmount: moneyFormatter(feeAmount), feePercentage: feePercentage });
    } else if (name === 'shouldBePublished') {
      value = event.target.checked;
      setProduct({ ...product, [name]: value });
    } else {
      setProduct({ ...product, [name]: value });
    }
  };

  const handleSelectChange = (valueArray) => {
    setProduct({ ...product, tags: valueArray });
  };

  const showAlert = (msg) => {
    setAlertMessage(msg);

    setTimeout(() => {
      setAlertMessage('');
    }, '6000');
    setIsSaving(false);
  };

  const getTags = () => {
    TagService.getAll()
      .then((response) => {
        setTags(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    async function loadParamsFirst() {
      await getTags();
    }

    loadParamsFirst();
  }, []);

  useEffect(() => {
    const getProduct = (productId) => {
      ProductService.get(accountId, productId)
        .then((response) => {
          let retrievedProduct = { ...response.data };

          retrievedProduct.tags = response.data.tags.map((tag) => {
            return tag.id;
          });

          retrievedProduct.feeAmount = moneyFormatter(response.data.price[0].paymentLink.fee);
          retrievedProduct.feePercentage = response.data.price[0].paymentLink.feePercentage;
          retrievedProduct.price = moneyFormatter(response.data.price[0].price);

          retrievedProduct.paymentType = response.data.price[0].paymentType;
          retrievedProduct.recurringInterval = response.data.price[0].recurringInterval ? response.data.price[0].recurringInterval : '';

          retrievedProduct.afterCompletion = response.data.price[0].paymentLink.afterCompletion;
          retrievedProduct.redirectURL = response.data.price[0].paymentLink.redirectURL;

          setProduct(retrievedProduct);
        })
        .catch((e) => {
          console.log(e);
        });
    };

    if (productId && productId !== 'new' && productId !== '') {
      setEditing(true);
      getProduct(productId);
    }
  }, [accountId, productId]);

  const saveProduct = () => {
    setIsSaving(true);

    if (product.name === '') {
      showAlert('Name is mandatory.');
      return;
    }

    if (product.price === '' || product.price === 0) {
      showAlert('Price is mandatory.');
      return;
    } else if (product.price * 100 < 300) {
      showAlert('Minimum price is 3$.');
      return;
    }

    if (product.paymentType === 'recurring' && product.recurringInterval === '') {
      showAlert('Recurring Interval must be selected.');
      return;
    }

    if (product.afterCompletion === 'redirect' && product.redirectURL === '') {
      showAlert('A valid redirect URL must be provided.');
      return;
    }
    // TODO agregar control de tags

    var data = {
      name: product.name,
      description: product.description,
      imageURL: product.imageURL,
      price: Math.round(parseFloat(product.price.replace(/,/g, '')) * 100),
      paymentType: product.paymentType,
      recurringInterval: product.recurringInterval,
      afterCompletion: product.afterCompletion,
      redirectURL: product.redirectURL,
      tags: product.tags,
      accountId: accountId,
      shouldBePublished: product.shouldBePublished
    };

    if (editing) {
      ProductService.update(accountId, productId, data)
        .then(() => {
          handleCloseModal(true, true);
        })
        .catch((e) => {
          console.log(e);
          setIsSaving(false);
          showAlert('Error saving product.');
        });
    } else {
      ProductService.create(accountId, data)
        .then(() => {
          handleCloseModal(true, true);
        })
        .catch((e) => {
          console.log(e);
          setIsSaving(false);
          showAlert('Error saving product.');
        });
    }
  };

  return (
    <div>
      <Snackbar open={alertMessage !== ''}>
        <Alert severity="error">{alertMessage}</Alert>
      </Snackbar>
      <Grid container>
        <FormControl sx={{ width: '48%', mr: '2%' }}>
          <InputLabel htmlFor="name">Name</InputLabel>
          <Input name="name" id="name" aria-describedby="name" onChange={handleInputChange} value={product.name} />
        </FormControl>
      </Grid>
      <Grid container sx={{ mt: 5, mb: 4 }}>
        <FormControl sx={{ width: '100%', mb: 1 }}>
          <InputLabel htmlFor="description">Description</InputLabel>
          <Input
            name="description"
            id="description"
            multiline
            rows={4}
            value={product.description}
            onChange={(event) => {
              setProduct({ ...product, description: event.target.value });
            }}
            fullWidth
          />
        </FormControl>
      </Grid>
      <div>
        <FormControl sx={{ width: '49%', mr: '1%', mt: 2 }}>
          <InputLabel htmlFor="price">Price</InputLabel>
          <Input
            name="price"
            id="price"
            autoComplete="price"
            aria-describedby="Price"
            onChange={handleInputChange}
            value={product.price}
            startAdornment={<InputAdornment position="start">$</InputAdornment>}
          />
        </FormControl>
        <FormControl sx={{ width: '49%', ml: '1%', mt: 2 }}>
          <InputLabel htmlFor="feePercentage">Fee Percentage</InputLabel>
          <Input
            name="feePercentage"
            id="feePercentage"
            disabled={true}
            aria-describedby="Fee Percentage"
            onChange={handleInputChange}
            value={product.feePercentage}
            startAdornment={<InputAdornment position="start">%</InputAdornment>}
          />
        </FormControl>
      </div>
      <div>
        <FormControl sx={{ width: '49%', mr: '1%', mt: 2 }}>
          <InputLabel htmlFor="feeAmount">Fee Amount</InputLabel>
          <Input
            name="feeAmount"
            id="feeAmount"
            disabled={true}
            aria-describedby="Fee Amount"
            value={product.feeAmount}
            startAdornment={<InputAdornment position="start">$</InputAdornment>}
          />
        </FormControl>
      </div>
      <div>
        <FormControl sx={{ width: '49%', mr: '1%', mt: 2 }}>
          <InputLabel htmlFor="paymentType">Payment Type</InputLabel>
          <Select
            labelId="paymentType-label"
            id="paymentType"
            name="paymentType"
            value={product.paymentType}
            label="Payment Type"
            onChange={handleInputChange}
          >
            {paymentTypes &&
              paymentTypes.map((paymentType) => (
                <MenuItem key={paymentType.value} value={paymentType.value}>
                  {paymentType.label}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        <FormControl sx={{ width: '49%', ml: '1%', mt: 2 }}>
          <InputLabel htmlFor="recurringInterval">Recurring Interval</InputLabel>
          <Select
            disabled={product.paymentType !== 'recurring'}
            labelId="recurringInterval-label"
            id="recurringInterval"
            name="recurringInterval"
            value={product.recurringInterval}
            label="Recurring Interval"
            onChange={handleInputChange}
          >
            {recurringIntervals &&
              recurringIntervals.map((recurringInterval) => (
                <MenuItem key={recurringInterval.value} value={recurringInterval.value}>
                  {recurringInterval.label}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </div>
      <Grid container>
        <FormControl sx={{ width: '49%', mr: '1%', mt: 2 }}>
          <InputLabel htmlFor="afterCompletion">After Completion Type</InputLabel>
          <Select
            labelId="afterCompletion-label"
            id="afterCompletion"
            name="afterCompletion"
            value={product.afterCompletion}
            label="After Completion Type"
            onChange={handleInputChange}
          >
            {afterCompletionTypes &&
              afterCompletionTypes.map((afterCompletionType) => (
                <MenuItem key={afterCompletionType.value} value={afterCompletionType.value}>
                  {afterCompletionType.label}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        <FormControl sx={{ width: '49%', ml: '1%', mt: 2 }}>
          <InputLabel htmlFor="redirectURL">Redirect URL</InputLabel>
          <Input
            name="redirectURL"
            id="redirectURL"
            disabled={product.afterCompletion !== 'redirect'}
            aria-describedby="Redirect URL"
            onChange={handleInputChange}
            value={product.redirectURL}
          />
        </FormControl>
      </Grid>
      <Grid container>
        <FormControl sx={{ width: '49%', mr: '1%', mt: 2 }}>
          <MultipleSelectChip
            objs={tags}
            label={'Tags'}
            handleSelectChange={handleSelectChange}
            selected={product?.tags?.length > 0 ? product.tags : []}
          ></MultipleSelectChip>
        </FormControl>
        <FormControl sx={{ width: '49%', ml: '1%', mt: 2 }}>
          <FormControlLabel
            control={<Checkbox checked={product.shouldBePublished} onChange={handleInputChange} name="shouldBePublished" color="primary" />}
            label="Publish on marketplace"
          />
        </FormControl>
      </Grid>
      <Divider sx={{ mt: 2, mb: 1 }} />
      {!isSaving ? (
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Button onClick={handleCloseModal} variant="contained" color="error">
            Cancel
          </Button>
          <Button onClick={saveProduct} variant="contained">
            Save
          </Button>
        </Box>
      ) : (
        <Box sx={{ textAlign: 'center' }}>
          <CircularProgress />
        </Box>
      )}
    </div>
  );
};
ProductForm.propTypes = {
  handleCloseModal: PropTypes.func.isRequired,
  productId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};
export default ProductForm;
