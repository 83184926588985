import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL + 'admin/dashboard/';

const axiosConfig = {
  withCredentials: true
};

const get = () => {
  return axios.get(API_URL, axiosConfig);
};

const DashboardService = {
  get
};

export default DashboardService;
