import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

const axiosConfig = {
  withCredentials: true
};

const getAll = (accountId, params) => {
  return axios.get(API_URL + `accounts/${accountId}/products/`, {
    params: params,
    withCredentials: true
  });
};

const get = (accountId, productId) => {
  return axios.get(API_URL + `accounts/${accountId}/products/${productId}`, axiosConfig);
};

const create = (accountId, data) => {
  return axios.post(API_URL + `accounts/${accountId}/products`, data, axiosConfig);
};

const update = (accountId, productId, data) => {
  return axios.put(API_URL + `accounts/${accountId}/products/${productId}`, data, axiosConfig);
};

const remove = (accountId, productId) => {
  return axios.delete(API_URL + `accounts/${accountId}/products/${productId}`, axiosConfig);
};

const uploadPic = (accountId, productId, file, onUploadProgress) => {
  let config = {
    ...axiosConfig,
    onUploadProgress
  };
  return axios.post(API_URL + `accounts/${accountId}/products/${productId}/pics`, file, config);
};

const getPics = (accountId, productId) => {
  return axios.get(API_URL + `accounts/${accountId}/products/${productId}/pics`, axiosConfig);
};

const removePic = (accountId, productId, picId) => {
  return axios.delete(API_URL + `accounts/${accountId}/products/${productId}/pics/${picId}`, axiosConfig);
};

const defaultPic = (accountId, productId, picId) => {
  return axios.post(API_URL + `accounts/${accountId}/products/${productId}/pics/${picId}`, {}, axiosConfig);
};

const ProductService = {
  getAll,
  get,
  create,
  update,
  remove,
  uploadPic,
  getPics,
  defaultPic,
  removePic
};

export default ProductService;
