import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import AccountEdit from './AccountEdit';
import AccountService from '../../services/account.service';
import { a11yProps } from '../../helpers/utils';
import completeProfiles from './AccountUtils';
import Products from '../products/Products';

import TabPanel from '../subcomponents/TabPanel';
import Modal from '../subcomponents/Modal';

import { deepOrange } from '@mui/material/colors';
import { Create as CreateIcon } from '@mui/icons-material';
import { CircularProgress, Snackbar, Alert, Button, Avatar, Paper, Grid, Box, Tab, Tabs, Typography } from '@mui/material';

import DOMPurify from 'dompurify';
const AccountProfile = () => {
  let params = useParams();
  let accountId = params.accountId;

  const [tabValue, setTabValue] = useState(0);
  const [account, setAccount] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [saved, setSaved] = useState(false);

  useEffect(() => {
    getAccount(accountId);
  }, [accountId]);

  const handleTabChange = (event, newValue) => {
    // if products tab
    if (newValue === 1) {
      console.log('Sales tab active');
      // should have a getSales() here
    }
    setTabValue(newValue);
  };

  function goToEditAccount() {
    // This should open an modal instead of a new page
    setOpenModal(true);
  }

  const handleCloseModal = (event, wasSaved = false) => {
    if (wasSaved) {
      setSaved(true);
      getAccount(accountId);
    }
    setOpenModal(false);
    setTimeout(() => {
      setSaved(false);
    }, '4000');
  };

  const getAccount = (id) => {
    AccountService.get(id)
      .then((response) => {
        let parsedData = response.data;

        parsedData['completedProfile'] = completeProfiles([response.data])[0].completedProfile;

        parsedData['receiveNewsByEmail'] = response.data.receiveNewsByEmail === '1' ? true : false;
        parsedData['bio'] = response.data.bio;

        setAccount(parsedData);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={3}>
        <Paper elevation={3} sx={{ width: '100%', p: 2 }}>
          {account.id !== undefined ? (
            <>
              {account.profilePicture ? (
                <Avatar sx={{ width: '80%', height: '250px', margin: 'auto' }} src={account.profilePicture} />
              ) : (
                <Avatar sx={{ bgcolor: deepOrange[500], width: '80%', height: '250px', margin: 'auto' }}>No picture</Avatar>
              )}
              <Button sx={{ paddingLeft: 0.3, marginTop: 2 }} onClick={goToEditAccount}>
                Edit profile
                <CreateIcon fontSize="small" sx={{ ml: 1 }} />
              </Button>
              <Typography component="div" variant="h6">
                <Box sx={{ fontWeight: 'bold' }} display="inline">
                  ID:
                </Box>{' '}
                {account.id}
              </Typography>
              <Typography component="div" variant="h6">
                <Box sx={{ fontWeight: 'bold' }} display="inline">
                  Name:
                </Box>{' '}
                {account.firstName + ' ' + account.lastName}
              </Typography>
              <Typography component="div" variant="h6">
                <Box sx={{ fontWeight: 'bold' }} display="inline">
                  Email:
                </Box>{' '}
                {account.email}
              </Typography>
              <Typography component="div" variant="h6">
                <Box sx={{ fontWeight: 'bold' }} display="inline">
                  Bio:
                </Box>
                <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(account.bio) }} />
              </Typography>
              <Typography component="div" variant="h6">
                <Box sx={{ fontWeight: 'bold' }} display="inline">
                  Stripe ID:
                </Box>{' '}
                {account.stripeAccountId ? account.stripeAccountId : '-'}
              </Typography>
            </>
          ) : (
            <Box sx={{ textAlign: 'center' }}>
              <CircularProgress />
            </Box>
          )}
        </Paper>
      </Grid>
      <Grid item xs={9}>
        {account.completedProfile !== true ? (
          <Alert severity="warning" variant="filled" sx={{ mb: 1 }}>
            <b>Missing some data on the profile: </b>
            {account.completedProfile}
          </Alert>
        ) : (
          ''
        )}
        <Snackbar open={saved} autoHideDuration={6000}>
          <Alert severity="success" variant="filled" sx={{ mb: 1 }}>
            The account was saved!
          </Alert>
        </Snackbar>
        <Modal open={openModal} title={'Edit account'}>
          <AccountEdit handleCloseModal={handleCloseModal} />
        </Modal>
        <Paper elevation={3} sx={{ width: '100%' }}>
          <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={tabValue} onChange={handleTabChange} aria-label="basic tabs example">
                <Tab label="Products" {...a11yProps(1)} />
                <Tab label="Sales" {...a11yProps(2)} />
              </Tabs>
            </Box>
            <TabPanel value={tabValue} index={0}>
              <Products />
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
              Sales
            </TabPanel>
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default AccountProfile;
