import React, { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

import useAuth from './hooks/useAuth';
import Navbar from './components/Navbar';
import { Routes, Route, Navigate } from 'react-router-dom';
import Login from './components/Login';
import TwoFactorAuth from './components/auth/TwoFA';
import AuthGuard from './utils/AuthGuard';

const App = () => {
  const { isLoggedIn } = useAuth();

  useEffect(() => {
    const env = process.env.REACT_APP_ENV;
    const baseTitle = 'Administrala';
    const title = env === 'Development' ? `[DEV] ${baseTitle}` : env === 'Local' ? `[Local] ${baseTitle}` : baseTitle;
    document.title = title;
  }, []);

  return (
    <>
      {!isLoggedIn ? (
        <Routes>
          <Route path="/twoFactorAuth" element={<TwoFactorAuth />} />
          <Route path="/login" element={<Login />} />
          <Route path="*" element={<Navigate to="/login" replace />} />
        </Routes>
      ) : (
        <AuthGuard>
          <Navbar />
        </AuthGuard>
      )}
    </>
  );
};

export default App;
