import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
const UploadImages = ({ id, setSaved, objUpload }) => {
  const [selectedFiles, setSelectedFiles] = useState(undefined);
  const [imagePreviews, setImagePreviews] = useState([]);
  const [imageBase64, setImageBase64] = useState([]);
  const [message, setMessage] = useState([]);
  const [progressInfos, setProgressInfos] = useState([]);
  const progressInfosRef = useRef(progressInfos);

  const selectFiles = async (event) => {
    const files = Array.from(event.target.files);
    const images64 = await Promise.all(files.map((file) => readFileAsDataURL(file)));

    setImageBase64(images64);
    setSelectedFiles(files);
    setImagePreviews(images64);
    setProgressInfos(files.map((file) => ({ percentage: 0, fileName: file.name })));
    setMessage([]);
  };

  const readFileAsDataURL = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };

  const uploadImages = () => {
    const files = Array.from(selectedFiles);
    progressInfosRef.current = { val: files.map((file) => ({ percentage: 0, fileName: file.name })) };
    files.forEach((file, i) => upload(i, file));
    setMessage([]);
  };

  const upload = async (idx, file) => {
    const requestBody = { image: imageBase64[idx] };
    try {
      await objUpload(...id, requestBody);
      updateProgressInfo(idx, 100);
      setSaved(true);
    } catch {
      updateProgressInfo(idx, 0);
      appendMessage(`Could not upload the image: ${file.name}`);
    }
  };

  const updateProgressInfo = (idx, percentage) => {
    const updatedProgressInfos = [...progressInfos];
    updatedProgressInfos[idx].percentage = percentage;
    setProgressInfos(updatedProgressInfos);
    progressInfosRef.current = updatedProgressInfos;
  };

  const appendMessage = (message) => {
    setMessage((prevMessage) => [...prevMessage, message]);
  };

  return (
    <div>
      <div className="row">
        <div className="col-8">
          <input type="file" multiple accept="image/*" onChange={selectFiles} />
        </div>

        <div className="col-4">
          <button className="btn btn-success btn-sm" disabled={!selectedFiles} onClick={uploadImages}>
            Upload
          </button>
        </div>
      </div>

      {progressInfos &&
        progressInfos.length > 0 &&
        progressInfos.map((progressInfo, index) => (
          <div className="mb-2" key={index}>
            <span>{progressInfo.fileName}</span>
            <div className="progress">
              <div
                className="progress-bar progress-bar-info"
                role="progressbar"
                aria-valuenow={progressInfo.percentage}
                aria-valuemin="0"
                aria-valuemax="100"
                style={{ width: progressInfo.percentage + '%' }}
              >
                {progressInfo.percentage}%
              </div>
            </div>
          </div>
        ))}

      {imagePreviews && (
        <div>
          {imagePreviews.map((img, i) => {
            return <img className="preview" src={img} alt={'image-' + i} key={i} style={{ maxWidth: '200px' }} />;
          })}
        </div>
      )}

      {message.length > 0 && (
        <div className="alert alert-secondary mt-2" role="alert">
          <ul>
            {message.map((item, i) => {
              return <li key={i}>{item}</li>;
            })}
          </ul>
        </div>
      )}
    </div>
  );
};

UploadImages.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  setSaved: PropTypes.func.isRequired,
  objUpload: PropTypes.func.isRequired
};

export default UploadImages;
