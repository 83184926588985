import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { TextField, Button, Container, Typography, Box } from '@mui/material';
import useAuth from '../../hooks/useAuth';

const TwoFactorAuth = () => {
  let navigate = useNavigate();
  const { user, twoFAVerify } = useAuth();
  const [code, setCode] = useState('');
  const [error, setError] = useState('');

  const handleChange = (e) => {
    const value = e.target.value;
    if (/^\d*$/.test(value) && value.length <= 6) {
      setCode(value);
    }
  };

  const handleSubmit = async () => {
    if (code.length !== 6) {
      setError('Please enter a valid 6-digit code.');
      return;
    }

    try {
      twoFAVerify(user.email, code).then((response) => {
        if (response.success) {
          navigate('/dashboard');
          window.location.reload();
        } else {
          setError('Failed to verify code. Please try again.');
        }
      });
    } catch (error) {
      console.error('Error verifying 2FA Code:', error);
      setError('Failed to verify code. Please try again.');
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" mt={5}>
        <Typography variant="h5" gutterBottom>
          Enter 2FA Code
        </Typography>
        <TextField
          label="6-digit code"
          variant="outlined"
          value={code}
          onChange={handleChange}
          error={Boolean(error)}
          helperText={error}
          fullWidth
          margin="normal"
          inputProps={{ maxLength: 6, inputMode: 'numeric', pattern: '[0-9]*' }}
        />
        <Button variant="contained" color="primary" onClick={handleSubmit} fullWidth>
          Verify Code
        </Button>
      </Box>
    </Container>
  );
};

export default TwoFactorAuth;
