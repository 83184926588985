import React, { useState, useEffect } from 'react';
import ProductService from '../../services/product.service';
import { useParams } from 'react-router-dom';
import { getRequestParams } from '../../helpers/utils';

import Grid from '@mui/material/Grid';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Snackbar from '@mui/material/Snackbar';
import CircularProgress from '@mui/material/CircularProgress';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import RefreshIcon from '@mui/icons-material/Refresh';
import Divider from '@mui/material/Divider';
import InputBase from '@mui/material/InputBase';
import AddBoxIcon from '@mui/icons-material/AddBox';
import ProductsListTable from './ProductsListTable';
import Modal from '../subcomponents/Modal';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Pagination from '@mui/material/Pagination';
import ProductEdit from './ProductEdit';

const Products = () => {
  let params = useParams();
  let accountId = params.accountId;

  const [products, setProducts] = useState([]);
  const [openModal, setOpenModal] = React.useState(false);
  const [currentProductId, setCurrentProductId] = React.useState('');
  const [title, setTitle] = React.useState('New product');
  const [saved, setSaved] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  const pageSizes = [10, 20, 30];

  const handlePageSizeChange = (event) => {
    setPageSize(event.target.value);
    setPage(1);
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const confirmDeletion = () => {
    retrieveProducts();
    setSaved('The product was deleted!');
    setTimeout(() => {
      setSaved('');
    }, '4000');
  };

  const handleCloseModal = (event, wasSaved = false) => {
    if (wasSaved) {
      setSaved('The product was saved!');
      retrieveProducts();
    }
    setCurrentProductId('');
    setOpenModal(false);
    setTimeout(() => {
      setSaved('');
    }, '4000');
  };

  const onChangeSearchQuery = (e) => {
    const searchQuery = e.target.value;
    setSearchQuery(searchQuery);
  };

  const retrieveProducts = () => {
    setProducts(null);
    const params = getRequestParams(searchQuery, page, pageSize);

    ProductService.getAll(accountId, params).then(
      (response) => {
        const { products, totalPages } = response.data;
        setProducts(products);
        setCount(totalPages);
      },
      (error) => {
        const _content = (error.response && error.response.data) || error.message || error.toString();

        setProducts(_content);
      }
    );
  };

  useEffect(retrieveProducts, [accountId, page, pageSize, searchQuery]);

  function goToProductEdit(id) {
    if (id) {
      setTitle('Edit product');
    } else {
      id = 'new';
      setTitle('New product');
    }
    setCurrentProductId(id);
  }

  useEffect(() => {
    if (currentProductId) {
      setOpenModal(true);
    }
  }, [currentProductId]);

  return (
    <div className="container">
      <Snackbar open={saved !== ''} autoHideDuration={6000}>
        <Alert severity="success" variant="filled" sx={{ mb: 1 }}>
          {saved}
        </Alert>
      </Snackbar>
      <Grid sx={{ mb: 1 }} container justifyContent="left">
        <Paper component="form" sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 500 }}>
          <IconButton sx={{ p: '10px' }} aria-label="refresh" onClick={retrieveProducts}>
            <RefreshIcon />
          </IconButton>
          <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
          <InputBase
            sx={{ ml: 1, flex: 1 }}
            placeholder="Search by name or email"
            inputProps={{ 'aria-label': 'Search by name or email' }}
            value={searchQuery}
            onChange={onChangeSearchQuery}
          />
          <IconButton type="button" sx={{ p: '10px' }} aria-label="search" onClick={retrieveProducts}>
            <SearchIcon />
          </IconButton>
          <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
          <IconButton
            type="button"
            sx={{ p: '10px' }}
            aria-label="search"
            onClick={() => {
              goToProductEdit();
            }}
          >
            <AddBoxIcon />
          </IconButton>
        </Paper>
      </Grid>
      <Modal open={openModal} title={title}>
        <ProductEdit handleCloseModal={handleCloseModal} productId={currentProductId} />
      </Modal>
      {products?.length > 0 ? (
        <ProductsListTable goToProductEdit={goToProductEdit} products={products} confirmDeletion={confirmDeletion} />
      ) : products?.length === 0 ? (
        <Box sx={{ mt: 4, mb: 4 }}>No products found.</Box>
      ) : (
        <Box sx={{ textAlign: 'center' }}>
          <CircularProgress />
        </Box>
      )}
      <Grid sx={{ mt: 2 }} container>
        <FormControl size="small" sx={{ width: '6em' }}>
          <InputLabel id="demo-simple-select-label">Page size</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={pageSize}
            label="Page size"
            onChange={handlePageSizeChange}
          >
            {pageSizes.map((size) => (
              <MenuItem key={size} value={size}>
                {size}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Pagination
          count={count}
          page={page}
          siblingCount={1}
          boundaryCount={1}
          variant="outlined"
          shape="rounded"
          onChange={handlePageChange}
          size="large"
        />
      </Grid>
    </div>
  );
};

export default Products;
