const completeProfiles = (accounts) => {
  accounts.forEach((account) => {
    if (!account.completedProfile) {
      if (!account.profilePicture) {
        account.completedProfile = 'Profile picture';
      }
      if (!account.bio) {
        account.completedProfile = typeof account.completedProfile === 'string' ? account.completedProfile + ', Bio' : 'Bio';
      }

      if (
        !account.socialMedia ||
        (!account.socialMedia.instagram &&
          !account.socialMedia.facebook &&
          !account.socialMedia.linkedin &&
          !account.socialMedia.tiktok &&
          !account.socialMedia.twitter)
      ) {
        account.completedProfile =
          typeof account.completedProfile === 'string' ? account.completedProfile + ', Social Media' : 'Social Media';
      }

      if (!account.completedProfile) {
        account.completedProfile = true;
      }
    } else {
      account.completedProfile = true;
    }
  });
  return accounts;
};

export default completeProfiles;
