import React, { useRef, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { a11yProps } from '../../helpers/utils';
import TabPanel from '../subcomponents/TabPanel';
import AccountForm from './AccountForm';
import ProfilePic from './ProfilePic';

import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Alert from '@mui/material/Alert';

const AccountEdit = ({ handleCloseModal }) => {
  let params = useParams();
  let accountId = params.accountId;
  const [currentTab, setCurrentTab] = useState(0);
  const tabRef = useRef();
  const [tabHeight, setTabHeight] = useState(0);

  useEffect(() => {
    setTabHeight(tabRef.current.clientHeight);
  }, [tabRef]);

  const handleChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={currentTab} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="Data" {...a11yProps(0)} />
          <Tab label="Profile Pic" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <TabPanel value={currentTab} index={0} innerRef={tabRef}>
        <AccountForm handleCloseModal={handleCloseModal} />
      </TabPanel>
      <TabPanel value={currentTab} index={1} sx={{ minHeight: tabHeight }}>
        {accountId ? (
          <ProfilePic handleCloseModal={handleCloseModal} accountId={accountId} />
        ) : (
          <Alert severity="warning">Account most be saved before uploading the profile pic!</Alert>
        )}
      </TabPanel>
    </Box>
  );
};

AccountEdit.propTypes = {
  handleCloseModal: PropTypes.func.isRequired
};

export default AccountEdit;
